<template>
  <v-container class="white py-0 px-0" fluid>
    <v-card flat>
      <v-toolbar flat dense class="grey lighten-5">
        <v-toolbar-title class="py-3 px-0">{{
          $t("route.ac")
        }}</v-toolbar-title>
        <v-spacer />
        <!-- <v-divider vertical inset class="d-none d-md-flex mx-2" />
        <v-btn class="d-none d-md-flex" text x-small :disabled="!selected" @click="clearSelectedRole()">
          <v-icon>mdi-circle-off-outline</v-icon>
          <span class="text--secondary">{{ $t("general.deselect") }}</span>
        </v-btn> -->
        <v-divider vertical inset class="d-none d-md-flex mx-2" />
        <v-text-field
          class="d-none d-md-flex"
          v-model="search"
          append-icon="mdi-magnify"
          :label="$t('general.search')"
          single-line
          hide-details
          dense
          style="width:0"
        ></v-text-field>
        <v-divider vertical inset class="d-none d-md-flex mx-2" />
        <v-btn
          :disabled="isReadOnly"
          text
          x-small
          color="green"
          @click="$router.push({ name: 'role', params: { id: 'new' } })"
        >
          <v-icon>mdi-shield-lock</v-icon>
          <span class="text--secondary">{{ $t("general.new") }}</span>
        </v-btn>
        <v-btn
          text
          x-small
          color="primary"
          :disabled="!selected"
          @click="editRole()"
        >
          <v-icon>mdi-shield-edit</v-icon>
          <span>{{$t("general.edit")}}</span>
        </v-btn>
        <v-btn
          text
          x-small
          color="red"
          v-if="selected && !['Administrator', 'Resource Manager', 'AMRF Administrator', 'User', 'Viewer'].includes(selected.name)"
          :disabled="!selected"
          @click="confirmDeleteRole()"
        >
          <v-icon>mdi-shield-remove</v-icon>
          <span>{{$t("general.delete")}}</span>
        </v-btn>
      </v-toolbar>

      <v-card-text class="overflow-y-auto px-0 py-0" :style="viewHeight()">
        <v-data-table
          dense
          :headers="headers"
          :items="getRoles"
          item-key="id"
          :search="search"
          show-select
          single-select
          @item-selected="selectionChanged"
          :value="selection"
          :loading="loading"
          :loading-text="$t('messages.loading')"
        >
          <template #item.data-table-select="{ item, isSelected, select }">
            <v-simple-checkbox
              on-icon="mdi-checkbox-marked-circle"
              off-icon="mdi-power-off"
              :value="isSelected"
              color="blue"
              light
              @input="select($event)"
              :ripple="false"
            ></v-simple-checkbox>
          </template>
          <!-- <template #item.actions="{ item }">
          <v-btn text x-small color="primary" @click="`/orgs/${item.id}`">
            <v-icon small>mdi-file-edit-outline</v-icon>
            <span>{{$t("general.edit")}}</span>
          </v-btn>
          <v-btn text x-small color="red" @click="deleteDialog = true">
            <v-icon small>mdi-delete</v-icon>
            <span>{{$t("general.delete")}}</span>
          </v-btn>
        </template> -->
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters, mapActions, mapState } from "vuex";

export default {
  name: "Roles",
  data() {
    return {
      search: "",
      loading: false,
      deleteDialog: false,
      headers: [
        { text: this.$t("general.id"), align: "start", value: "id", width: 50 },
        { text: this.$t("general.name"), value: "name" },
        { text: this.$t("general.description"), value: "description" },
      ],
    };
  },
  mounted() {
    this.loading = true;
    this.loadRoles().then(() => this.loading = false).catch(() => this.loading = false);
  },
  computed: {
    ...mapGetters("roles", ["getRoles", "getSelectedRole"]),
    ...mapState({
      selected() {
        return this.getSelectedRole;
      },
      selection() {
        const s = this.selected;
        return s ? [s] : [];
      },
    }),

  },
  methods: {
    ...mapActions("roles", [
      "loadRoles",
      "deleteRole",
      "setSelectedRole",
      "clearSelectedRole",
    ]),
    ...mapActions("ui", ["showDialog", "closeDialog"]),

    selectionChanged(event) {
      if (event.value) {
        this.setSelectedRole(event.item);
      } else {
        this.clearSelectedRole();
      }
    },
    editRole() {
      if (this.selected) {
        this.$router.push({
          name: "role",
          params: { id: this.selected.id },
        });
      }
    },
    confirmDeleteRole() {
      if (this.selected) {
        // this.deleteDialog = true;
        const dialog = {
          title: "Delete",
          text: `Are you sure you want to delete ${
            this.selected ? this.selected.name : ""
          } role?`,
          actions: [
            {
              text: "Yes",
              click: () => {
                this.deleteRole(this.selected.id);
                this.closeDialog();
              },
              color: "red",
            },
            {
              text: "No",
              click: () => this.closeDialog(),
              color: "primary",
            },
          ],
        };

        this.showDialog(dialog);
      }
    },
  },
};
</script>
